<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4">
			<b-col cols="12">
				<em class="fa fa-backward"></em>
				<a @click="goToBundleOffers" href=""> Bundle Offers</a>
			</b-col>
			<b-col v-if="item">
				<b-form @submit="onSubmit" encytpe="multipart/form-data">
					<div class="card">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Edit Bundle Offer ({{ this.form.long_name }})
							</h5>
						</div>
						<div class="card-body">
							<b-row align-h="center">
								<b-col lg="4">
									<div v-if="form.image_normal">
										<b-img-lazy
											:src="form.image_normal"
											fluid-grow
											thumbnail
											alt="Responsive image"
										></b-img-lazy>
									</div>
									<b-form-group class="mt-3" label="Upload Image">
										<b-form-file
											id="uploaded_file"
											:multiple="false"
											v-model="form.uploaded_file"
											:state="null"
											name="uploaded_file"
											accept="image/jpeg, image/png"
											@change="onFilePicked"
											placeholder="Choose a file or drop it here..."
											drop-placeholder="Drop file here..."
										>
										</b-form-file>
										<div class="mt-3">
											Selected file:
											{{ form.uploaded_file ? form.uploaded_file.name : "" }}
										</div>
									</b-form-group>
								</b-col>
							</b-row>
							<hr />
							<b-row>
								<b-col lg="6">
									<b-form-group label="Name *">
										<b-form-input
											id="long_name"
											v-model="form.long_name"
											type="text"
											placeholder="input name"
											required
										>
										</b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6">
									<b-form-group label="Offers Status *">
										<b-form-select
											id="offer_status"
											track-by="value"
											v-model="form.offer_status"
											:options="offerStatusOptions"
											required
										>
										</b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Item Type *">
										<b-form-select
											id="offer_item_type"
											track-by="value"
											v-model="form.item_type"
											:options="itemTypeOptions"
											@change="offerItemTypeChange"
											required
										>
										</b-form-select>
									</b-form-group>
								</b-col>
								<b-col lg="6">
									<b-form-group label="Item *">
										<multiselect
											v-model="form.item"
											label="text"
											track-by="value"
											open-direction="bottom"
											:options="offerItemSearchOptions"
											:searchable="true"
											:select-size="4"
											:loading="isOfferItemSearch"
											:close-on-select="false"
											:clear-on-select="false"
											:preserve-search="false"
											:preselect-first="false"
											:multiple="true"
											:taggable="false"
											@search-change="offerItemFind"
											required
										>
										</multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<hr />
							<b-row>
                <b-col lg='4'>
                  <b-form-group label='Tier'>
                    <multiselect v-model='form.price_tier' placeholder='Search Tier' label='tier_code'
                      :custom-label="nameWithPrice" track-by='tier_code' open-direction='bottom'
                      :options='priceUsdOptions' :searchable='true' :loading='isPriceUsdSearch' :multiple='false'
                      @search-change='priceUsdFind' @input="tierSelected">
                    </multiselect>
                    <dfn class="text-muted">* set all price by ios tier</dfn>
                  </b-form-group>
                </b-col>
              </b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Price USD *">
										<b-form-input
											id="price_usd"
											v-model.number="form.price_usd"
											type="number"
											step="any"
											placeholder="example 1.99"
											required
										>
										</b-form-input>
									</b-form-group>
								</b-col>
								<b-col lg="6">
									<b-form-group label="Price IDR *">
										<b-form-input
											id="price_idr"
											v-model.number="form.price_idr"
											type="number"
											step="any"
											placeholder="example 99000 (no sparator)"
											required
										>
										</b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row>
								<b-col lg="4">
									<b-form-group label="IOS Tier *">
										<multiselect
											v-model="form.ios_tier"
											label="tier_code"
											track-by="tier_code"
											placeholder="Ios Tier"
											open-direction="bottom"
											:options="iosTierSearchOptions"
											:searchable="true"
											:loading="isIosTierSearch"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
											:preselect-first="false"
											:multiple="false"
											:taggable="false"
											@search-change="iosTierFind"
											required
										>
										</multiselect>
									</b-form-group>
								</b-col>
								<b-col lg="4">
									<b-form-group label="IOS Price USD">
										<b-input-group-text id="ios_price_usd">
											{{ form.ios_tier ? form.ios_tier.tier_price : 0 | formatUSD }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
								<b-col lg="4">
									<b-form-group label="IOS Price IDR">
										<b-input-group-text id="ios_price_idr">
											{{ form.ios_tier ? form.ios_tier.price_idr : 0 | formatIDR }}
										</b-input-group-text>
									</b-form-group>
								</b-col>

								<b-col lg="4">
									<b-form-group label="Android Tier *">
										<multiselect
											v-model="form.android_tier"
											label="tier_code"
											track-by="tier_code"
											placeholder="Ios Tier"
											open-direction="bottom"
											:options="androidTierSearchOptions"
											:searchable="true"
											:loading="isAndroidTierSearch"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
											:preselect-first="false"
											:multiple="false"
											:taggable="false"
											@search-change="androidTierFind"
											required
										>
										</multiselect>
									</b-form-group>
								</b-col>
								<b-col lg="4">
									<b-form-group label="Android Price USD">
										<b-input-group-text id="android_price_usd">
											{{ form.android_tier ? form.android_tier.tier_price : 0 | formatUSD }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
								<b-col lg="4">
									<b-form-group label="Android Price IDR">
										<b-input-group-text id="android_price_idr">
											{{ form.android_tier ? form.android_tier.price_idr : 0 | formatIDR }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
							<hr />
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-checkbox
											v-model="form.is_free"
											:value="true"
											:unchecked-value="false"
										>
											Free Offer
										</b-form-checkbox>
                    <b-form-checkbox v-model='allow_diff_price' :value=true :unchecked-value=false
                      v-if="checkbox_diff_price"> Allow Different Price
                    </b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row>
								<b-col>
									<b-button variant="primary" type="submit">Update</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { BundlePath } from "../../router/marketing";
import constant from "../../store/constant";

export default {
	name: "newBundleOffer",
	data() {
		return {
			debounce: null,
			offer_id: null,
			form: {
				long_name: null,
				price_usd: null,
				price_idr: null,
				ios_tier: null,
				android_tier: null,
				is_free: false,
				offer_status: null,
				item_type: null,
				image_normal: null,
				item: [],
				uploaded_file: null,
			},
			ios_price_usd: 0,
			ios_price_idr: 0,
			android_price_usd: 0,
			android_price_idr: 0,
			isOfferItemSearch: false,
			offerItemSearchOptions: [],
			isIosTierSearch: false,
			iosTierSearchOptions: [],
			isAndroidTierSearch: false,
			androidTierSearchOptions: [],
			offerStatusOptions: [
				{ value: constant.OFFER_STATUS.READY_FOR_SALE, text: "On Sale" },
				{ value: constant.OFFER_STATUS.NOT_FOR_SALE, text: "Not Sale" },
			],
			itemTypeOptions: [
				{ value: "magazine", text: constant.ITEM_TYPES.MAGAZINE },
				{ value: "book", text: constant.ITEM_TYPES.BOOK },
			],
      price_tier: null,
      isPriceUsdSearch: false,
      priceUsdOptions: [],
      allow_diff_price: false,
      checkbox_diff_price: false,
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.offersBundle.isError,
			isLoading: (state) => state.offersBundle.isLoading,
			errorMessage: (state) => state.offersBundle.errorMessage,
			successMessage: (state) => state.offersBundle.successMessage,
			item: (state) => state.offersBundle.item,
		}),
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);
			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(BundlePath);
		},
		item: function() {
			if (!this.item) {
				this.messageAlert("error", "not found");
				return;
			}
			if (!Object.keys(this.item).length) return;
			this.setEditOffers();
		},
	},
	created() {
		this.offer_id = this.$route.params.id;
		this.fetchOffersById(this.offer_id);
    this.priceUsdFind('c.usd.');
	},
	methods: {
		...mapActions("offersBundle", ["updateBundleOffers", "fetchOffersById"]),
		...mapActions("tiercodeAndroid", {
			searchAndroidTier: "searchAndroidTier",
      fetchAndroidTierById: 'fetchAndroidTierById',
		}),
		...mapActions("tiercodeIOS", {
			searchIosTier: "searchIosTier",
      fetchIosTierById: 'fetchIosTierById',
		}),
		...mapActions("itemcodeOffer", {
			searchOfferItem: "Search",
		}),
		onSubmit(event) {
			event.preventDefault();
      this.checkbox_diff_price = false;
      if (!this.checkDiffPrice()) {
        this.messageAlert('warning', 'found different price. please allow checkbox to continue!', 5000);
        this.checkbox_diff_price = true;
        if (!this.allow_diff_price) return
      }
			const data = this.form;
			const formData = new FormData();
			formData.append("offer_id", this.offer_id);
			formData.append("android_tier_id", data.android_tier.id);
			formData.append("ios_tier_id", data.ios_tier.id);
			formData.append("offer_status", data.offer_status);
			formData.append("price_usd", data.price_usd);
			formData.append("price_idr", data.price_idr);
			formData.append("is_free", data.is_free);
			formData.append("long_name", data.long_name);
			formData.append("item_type", data.item_type);
			formData.append("item", JSON.stringify(data.item));
			formData.append("uploaded_file", data.uploaded_file);
			this.updateBundleOffers(formData);
		},
		onFilePicked(event) {
			const files = event.target.files;
			const fileReader = new FileReader();
			fileReader.addEventListener("error", () => {
				this.form.image_normal = null;
				this.form.uploaded_file = null;
			});
			fileReader.addEventListener("load", () => {
				this.form.image_normal = fileReader.result;
				this.form.uploaded_file = files[0];
			});
			fileReader.readAsDataURL(files[0]);
		},
		offerItemFind(query) {
			if (!query) return;

			this.isOfferItemSearch = true;
			let itemType = this.form.item_type;
			if (itemType !== "magazine" && itemType !== "book") {
				itemType = "magazine";
			} else {
				itemType = this.form.item_type;
			}
			let listItem = this.form.item;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.searchOfferItem({ q: query, item_type: itemType, current_item: listItem })
					.then((response) => {
						this.offerItemSearchOptions = response.data.data.rows;
						this.isOfferItemSearch = false;
					})
					.catch(() => {
						this.isOfferItemSearch = false;
					});
			}, 1200);
		},
		offerItemTypeChange() {
			this.form.item = [];
			this.offerItemSearchOptions = [];
		},
		iosTierFind(query) {
			if (!query) return;

			this.isIosTierSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.searchIosTier({ q: query })
					.then((response) => {
						this.iosTierSearchOptions = response.data.data.rows;
						this.isIosTierSearch = false;
					})
					.catch(() => {
						this.isIosTierSearch = false;
					});
			}, 1200);
		},
		androidTierFind(query) {
			if (!query) return;

			this.isAndroidTierSearch = true;
			const offerId = this.offer_id;
			clearTimeout(this.debounce);

			this.debounce = setTimeout(() => {
				this.searchAndroidTier({ q: query, offer_id: offerId })
					.then((response) => {
						this.androidTierSearchOptions = response.data.data.rows;
						this.isAndroidTierSearch = false;
					})
					.catch(() => {
						this.isAndroidTierSearch = false;
					});
			}, 1200);
		},
		setEditOffers() {
			const data = this?.item?.database;
			this.form.long_name = data?.long_name;
			this.form.price_usd = data?.price_usd;
			this.form.price_idr = data?.price_idr;
			this.ios_price_usd = 0;
			this.ios_price_idr = 0;
			this.android_price_usd = 0;
			this.android_price_idr = 0;
			this.form.is_free = data?.is_free;
			this.offer_id = data?.id;
			this.form.offer_status = data?.offer_status;
			this.form.uploaded_file = null;
			this.form.item_type = this?.item?.item_type;
			this.form.item = this?.item?.items;

			if (data?.image_normal != null && data?.image_normal != undefined && data?.image_normal != "") {
				this.form.image_normal =
					constant.IMG_URL_STATIC_OFFER.URL + data?.image_normal;
			} else {
				this.form.image_normal = null;
			}

			const androidTier = (data?.platform_offers ?? []).find(
				(val) => val.platform_id == constant.PLATFORM.ANDROID
			);
			const iosTier = (data?.platform_offers ?? []).find(
				(val) => val.platform_id == constant.PLATFORM.IOS
			);

			if (androidTier) {
				this.fetchAndroidTierById(androidTier.tier_id)
				.then((response) => {
					this.form.android_tier = response;
					this.android_price_usd = response?.tier_price;
					this.android_price_idr = response?.price_idr;
					this.androidTierSearchOptions = [response];
				});
			}
			if (iosTier) {
				this.fetchIosTierById(iosTier.tier_id)
				.then((response) => {
					this.form.ios_tier = response;
					this.ios_price_usd = response?.tier_price;
					this.ios_price_idr = response?.price_idr;
					this.iosTierSearchOptions = [response];
				});
			}
		},
    checkDiffPrice() {
      const web = parseFloat(this.form.price_idr);
      const ios = parseFloat(this.form.ios_tier?.price_idr);
      const android = parseFloat(this.form.android_tier?.price_idr);
      const priceCheck = new Set([web, ios, android]);
      return (priceCheck.size == 1);
    },
    priceUsdFind(query) {
      if (!query) return;

      this.isPriceUsdSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          const rows = (response.data?.data?.rows ?? []);
          this.priceUsdOptions = rows;
          this.isPriceUsdSearch = false;
        }).catch(() => {
          this.isPriceUsdSearch = false;
        });
      }, 600);
    },
    nameWithPrice({ tier_price, tier_code }) {
      return `${tier_price} — [${tier_code}]`;
    },
    async tierSelected(data) {
      if (!data) return;
      this.searchIosTier({ q: data?.tier_code }).then((response) => {
        this.iosTierSearchOptions = response.data.data?.rows;
        this.form.ios_tier = response.data.data?.rows[0] ?? null;
      });
      this.searchAndroidTier({ q: data?.tier_code }).then((response) => {
        this.androidTierSearchOptions = response.data.data.rows;
        this.form.android_tier = response.data.data?.rows[0] ?? null;
      });
      this.form.price_usd = parseFloat(data?.tier_price);
      this.form.price_idr = parseFloat(data?.price_idr);
    },
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
		goToBundleOffers(event) {
			event.preventDefault();
			this.$router.push(BundlePath);
		},
	},
};
</script>
